import { ReactNode } from 'react'
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form'
import get from 'lodash-es/get'

import { FormFieldValidation } from '@/lib/core/interfaces/forms'
import { SelectDefault, SelectProps } from '@/lib/core/components/Select'
import { ValidationBadge } from '@/lib/core/components/ValidationBadge'
import {
  FieldError,
  FieldErrorMessage,
  InputWrapper,
  Label,
  LeftAdorment,
} from '@/lib/core/components/TextField/TextField.styles'

export type SelectFieldProps = {
  disabled?: boolean
  displayInline?: boolean
  label?: string
  leftAdorment?: ReactNode
  name: string
  placeholder?: string | ReactNode
  required?: boolean
  selectProps?: Partial<SelectProps>
  validation?: FormFieldValidation
  controllerProps?: Omit<ControllerProps, 'name' | 'control' | 'render'>
}

export const SelectField = ({
  disabled,
  displayInline,
  label,
  leftAdorment,
  name,
  selectProps,
  validation = FormFieldValidation.IDLE,
  controllerProps,
  ...props
}: SelectFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const error = get(errors, `${name}.message`)
  const isValidating = validation === FormFieldValidation.VALIDATING
  const isDisabled = disabled || isValidating

  const value = useWatch({ name, control })

  selectProps ||= {}
  if ('value' in props) {
    // @ts-expect-error Weird typing
    selectProps.value ||= props?.value
  } else if (value !== undefined) {
    // @ts-expect-error Weird typing
    selectProps.value ||= selectProps.options?.find((option) => option.value === value)
  }

  return (
    <>
      <Label
        $required={!!label && props.required}
        $displayInline={displayInline}
        $disabled={disabled}
        htmlFor={name}
      >
        {label}
      </Label>
      <InputWrapper $disabled={isDisabled}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <SelectDefault
              aria-labelledby={name}
              onChange={(option) => field.onChange((option as { value: unknown }).value)}
              isDisabled={isDisabled}
              hasError={!!error}
              leftAdorment={!!leftAdorment}
              ref={field.ref}
              {...props}
              {...selectProps}
            />
          )}
          {...controllerProps}
        />
        {validation !== FormFieldValidation.IDLE && (
          <ValidationBadge placement="formControl" status={validation} />
        )}
        {leftAdorment && <LeftAdorment>{leftAdorment}</LeftAdorment>}
      </InputWrapper>
      <FieldError>
        {error && (
          <FieldErrorMessage
            $displayInline={displayInline}
            $isValidating={validation !== FormFieldValidation.IDLE}
          >
            {error}
          </FieldErrorMessage>
        )}
      </FieldError>
    </>
  )
}
