import styled, { css } from 'styled-components'

export type StyledOptionProps = {
  borderColor?: string
  isDisabled?: boolean
  isFirst?: boolean
  isFocused?: boolean
  isSelected?: boolean
  minHeight?: string
}

export const StyledOption = styled.div<StyledOptionProps>`
  display: flex;
  align-items: center;
  min-height: ${({ theme, minHeight }) => minHeight || theme.layout.spacing(7)};
  border-top: ${({ theme, borderColor }) => `1px solid ${borderColor || theme.colors.border}`};
  padding: ${({ theme: { layout } }) => `${layout.spacing(1)} ${layout.spacing(2)}`};
  background: #fff;
  color: ${({ theme: { colors }, isDisabled }) => (isDisabled ? colors.disabled : colors.text)};
  ${({ isSelected, isFocused, theme }) =>
    (isFocused || isSelected) &&
    css`
      background-color: ${theme.colors.backgroundActive};
    `};

  &:first-child {
    border-top-width: 0;
  }
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`

export const StyledActionOption = styled(StyledOption)`
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  width: 100%;
  color: ${({ theme: { colors } }) => colors.active};
  line-height: 24px;
  cursor: pointer;
`
