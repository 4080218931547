import { ForwardedRef, ReactNode, useEffect, useRef } from 'react'
import ReactSelect, { components, Props, Theme } from 'react-select'
import { isFunction } from 'lodash-es'

import { SelectOption } from '@/lib/core/components/Select'
import { IndicatorsContainer, MenuList, Option } from '@/lib/core/components/Select/components'
import { getSelectTheme, stylesConfig } from '@/lib/core/components/Select/Select.styles'

import { forwardRefWrapper } from '@/lib/utils/forwardRefWrapper'

export type SelectProps = Props<SelectOption> & {
  actionOption?: ReactNode
  actionCallback?: () => void
  selectTheme?: (theme: Theme) => Theme
  menuPlacement?: 'bottom' | 'top'
  hasDisabledValue?: boolean
  hasError?: boolean
  leftAdorment?: boolean
} & ({ 'aria-label': string } | { 'aria-labelledby': string })

export type IsMulti = boolean

const SelectWrappedBase = ({
  actionOption,
  actionCallback = () => ({}),
  menuPlacement = 'bottom',
  hasDisabledValue,
  selectTheme = (theme) => getSelectTheme(theme, { menuPlacement }),
  hasError,
  leftAdorment,
  forwardedRef,
  ...props
}: SelectProps & { forwardedRef?: ForwardedRef<HTMLElement> }) => {
  const select = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (forwardedRef) {
      if (isFunction(forwardedRef)) {
        forwardedRef(select.current)
      } else {
        forwardedRef.current = select.current
      }
    }
  }, [forwardedRef, select.current])

  return (
    <ReactSelect
      // @ts-expect-error This is fine as we are using a ref
      ref={select}
      theme={selectTheme}
      menuPlacement={menuPlacement}
      styles={stylesConfig}
      components={{
        Control: (controlProps) =>
          components.Control({
            ...controlProps,
            // @ts-expect-error This has been working ergo fine
            hasDisabledValue,
            hasError,
            leftAdorment,
          }),
        IndicatorsContainer,
        MenuList: MenuList({
          actionCallback: () => {
            select?.current?.blur()
            actionCallback()
          },
          actionOption,
        }),
        Option,
      }}
      {...props}
    />
  )
}

export const SelectDefault = forwardRefWrapper(SelectWrappedBase)
