import { StylesConfig, Theme } from 'react-select'
import { DefaultTheme } from 'styled-components'

import { theme as mainTheme } from '@/lib/themes'

import { SelectOption } from '@/lib/core/components/Select'

import { shadeHexColor } from '@/lib/utils/colors'

export type Colors = Theme['colors'] & DefaultTheme['colors']

export type ThemeOptions = {
  menuPlacement: 'top' | 'bottom'
}

export const stylesConfig: StylesConfig<SelectOption> = {
  control: (provided, { theme, menuIsOpen, className, isDisabled, ...props }) => {
    const hasDisabledValue = (props as Record<string, unknown>).hasDisabledValue as boolean
    const hasError = (props as Record<string, unknown>).hasError as boolean
    const leftAdorment = (props as Record<string, unknown>).leftAdorment as boolean
    const colors = theme.colors as Colors
    let borderColor = hasError ? colors.error : colors.border

    if (isDisabled) {
      borderColor = colors.disabled
    } else if (hasDisabledValue) {
      borderColor = colors.error
    }

    return {
      ...provided,
      ':hover': {
        borderColor,
      },
      background: '#fff',
      border: `1px solid ${borderColor}`,
      borderRadius: menuIsOpen
        ? ((theme as Record<string, unknown> & Theme).controlRadius as string)
        : '10px',
      boxShadow: 'none',
      minWidth: '92px',
      paddingLeft: leftAdorment ? '52px' : 'initial',
      transition: 'none',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }
  },
  menu: (provided, { theme, placement }) => ({
    ...provided,
    border: `1px solid ${(theme.colors as Colors).border}`,
    borderBottomWidth: placement === 'top' ? 0 : 'auto',
    borderRadius: ((theme as Record<string, unknown> & Theme).menuRadius as string) || '10px',
    borderTopWidth: placement === 'bottom' ? 0 : 'auto',
    boxShadow: 'none',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  // This is controlled by src/lib/core/components/Select/components/Option.styles.tsx
  option: () => ({ }),
  singleValue: (provided, { isDisabled, theme }) => {
    const colors = theme.colors as Colors

    return {
      ...provided,
      color: isDisabled ? colors.disabled : colors.text,
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px 0 16px',
  }),
}

export const getSelectTheme = (theme: Theme, { menuPlacement }: ThemeOptions) => {
  const controlRadius = {
    bottom: '10px 10px 0 0',
    top: '0 0 10px 10px',
  }[menuPlacement]
  const menuRadius = {
    bottom: '0 0 10px 10px',
    top: '10px 10px 0 0',
  }[menuPlacement]

  return {
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      ...mainTheme.colors,
      danger: mainTheme.colors.error,
      dangerLight: shadeHexColor(mainTheme.colors.error, 0.5),
      primary: mainTheme.colors.active,
    },
    controlRadius,
    menuRadius,
    spacing: {
      baseUnit: 4,
      controlHeight: 56,
      menuGutter: 0,
    },
  }
}

export const getPaginationTheme = (theme: Theme, options: ThemeOptions) => {
  const defaultTheme = getSelectTheme(theme, options)

  return {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      border: '#ccc',
      primary: mainTheme.colors.text,
    },
    spacing: {
      ...defaultTheme.spacing,
      controlHeight: 48,
    },
  }
}
