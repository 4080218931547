import { ReactNode } from 'react'
import { MenuListProps } from 'react-select'

import { SelectOption } from '@/lib/core/components/Select'
import { StyledActionOption } from '@/lib/core/components/Select/components/Option.styles'

type SettingsProps = {
  actionOption?: ReactNode
  actionCallback?: () => void
}

export const MenuList =
  ({ actionOption, actionCallback }: SettingsProps) =>
  // eslint-disable-next-line react/display-name
  (props: MenuListProps<SelectOption>) => {
    const { children, innerProps, innerRef, getStyles } = props
    const menuListStyles = getStyles('menuList', props)

    return (
      <div
        // @ts-expect-error There is an issue with passing styles on because of the getStyles function
        css={
          {
            ...menuListStyles,
            maxHeight: (menuListStyles.maxHeight as number) - 1,
          }
        }
        ref={innerRef}
        {...innerProps}
      >
        {actionOption && (
          <StyledActionOption onClick={actionCallback}>{actionOption}</StyledActionOption>
        )}
        {children}
      </div>
    )
  }
