import { IndicatorsContainerProps } from 'react-select'

import { ChevronDownIcon, ChevronUpIcon } from '@/lib/core/components/Icon'
import { SelectOption } from '@/lib/core/components/Select'
import { Colors } from '@/lib/core/components/Select/Select.styles'
import { IndicatorWrapper } from '@/lib/core/components/Select/components/IndicatorsContainer.styles'

export const IndicatorsContainer = ({
  selectProps,
  theme,
}: IndicatorsContainerProps<SelectOption>) => (
  <IndicatorWrapper color={(theme.colors as Colors).primary}>
    {selectProps.menuIsOpen ? <ChevronUpIcon size="24" /> : <ChevronDownIcon size="24" />}
  </IndicatorWrapper>
)
