import styled from 'styled-components'

export type TooltipStyleProps = {
  $arrowOffset: number
  $contentPadding: number
}

export const Arrow = styled.span<TooltipStyleProps>`
  display: block;
  width: 0;
  height: 0;

  &[data-popper-arrow^='top'] {
    top: 100%;
    border-top: ${({ theme: { colors } }) => `8px solid ${colors.backgroundOpposite}`};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  &[data-popper-arrow^='bottom'] {
    bottom: 100%;
    border-right: 8px solid transparent;
    border-bottom: ${({ theme: { colors } }) => `8px solid ${colors.backgroundOpposite}`};
    border-left: 8px solid transparent;
  }

  &[data-popper-arrow^='right'] {
    right: 100%;
    border-top: 8px solid transparent;
    border-right: ${({ theme: { colors } }) => `8px solid ${colors.backgroundOpposite}`};
    border-bottom: 8px solid transparent;
  }

  &[data-popper-arrow^='left'] {
    left: 100%;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: ${({ theme: { colors } }) => `8px solid ${colors.backgroundOpposite}`};
  }

  &[data-popper-arrow^='top'][data-popper-arrow$='start'],
  &[data-popper-arrow^='bottom'][data-popper-arrow$='start'] {
    margin-left: ${({ $arrowOffset }) => `${$arrowOffset}px`};
  }

  &[data-popper-arrow^='top'][data-popper-arrow$='end'],
  &[data-popper-arrow^='bottom'][data-popper-arrow$='end'] {
    margin-left: ${({ $arrowOffset }) => `-${$arrowOffset}px`};
  }

  &[data-popper-arrow^='right'][data-popper-arrow$='start'],
  &[data-popper-arrow^='left'][data-popper-arrow$='start'] {
    margin-top: ${({ $arrowOffset }) => `${$arrowOffset}px`};
  }

  &[data-popper-arrow^='right'][data-popper-arrow$='end'],
  &[data-popper-arrow^='left'][data-popper-arrow$='end'] {
    margin-top: ${({ $arrowOffset }) => `-${$arrowOffset}px`};
  }
`

export const Container = styled.div<TooltipStyleProps>`
  position: relative;
  border-radius: 5px;
  padding: ${({ theme: { layout }, $contentPadding }) => layout.spacing($contentPadding)};
  background: ${({ theme: { colors } }) => colors.backgroundOpposite};
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
  line-height: 1.5;

  &[data-placement^='top'][data-placement$='start'],
  &[data-placement^='bottom'][data-placement$='start'] {
    transform: ${({ $arrowOffset }) => `translateX(-${$arrowOffset}px)`};
  }

  &[data-placement^='top'][data-placement$='end'],
  &[data-placement^='bottom'][data-placement$='end'] {
    transform: ${({ $arrowOffset }) => `translateX(${$arrowOffset}px)`};
  }

  &[data-placement^='right'][data-placement$='start'],
  &[data-placement^='left'][data-placement$='start'] {
    transform: ${({ $arrowOffset }) => `translateY(-${$arrowOffset}px)`};
  }

  &[data-placement^='right'][data-placement$='end'],
  &[data-placement^='left'][data-placement$='end'] {
    transform: ${({ $arrowOffset }) => `translateY(${$arrowOffset}px)`};
  }
`
