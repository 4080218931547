import styled from 'styled-components'

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { breadcrumbs } }) => breadcrumbs.color};

  ol {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    margin-left: ${({ theme: { layout } }) => layout.spacing(1)};
    padding: 0;
    list-style: none;
    gap: 10px;

    li {
      line-height: 1.333rem;

      a {
        color: ${({ theme: { breadcrumbs } }) => breadcrumbs.color};
        font-size: 14px;
        text-decoration: none;
        text-transform: capitalize;

        &::after {
          content: '/';
          bottom: -3px;
          left: auto;
          margin-left: 10px;
          border: none;
        }
      }
    }

    li:last-child {
      a {
        &::after {
          content: '';
        }
      }
    }
  }
`
