import { ReactNode } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react/headless'

import { Arrow, Container } from './Tooltip.styles'

export type TooltipProps = TippyProps & {
  arrowOffset?: number
  children: ReactNode
  contentPadding?: number
  tooltipContent: ReactNode
}

export const Tooltip = ({
  arrowOffset = 6,
  children,
  contentPadding = 1,
  tooltipContent,
  ...props
}: TooltipProps) => (
  <Tippy
    {...props}
    render={(attrs) => (
      <Container
        $arrowOffset={arrowOffset}
        $contentPadding={contentPadding}
        tabIndex={-1}
        {...attrs}
      >
        {tooltipContent}
        <Arrow
          $arrowOffset={arrowOffset}
          $contentPadding={contentPadding}
          data-popper-arrow={attrs['data-placement']}
        />
      </Container>
    )}
  >
    {children}
  </Tippy>
)
