import Select, * as ReactSelect from 'react-select'

export type SelectOption<T = string | number, I = Record<string, unknown>> = {
  label: string
  value: T
  data?: I
}
export type IsMulti = boolean
export type { SelectProps } from './SelectDefault'

export { Select }
export { ReactSelect }
export { getSelectTheme } from './Select.styles'
export * from './SelectDefault'
export * from './SelectField'
