import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'
import { useFlag } from '@/feature/feature-flags'

import { HomeIcon } from '@/lib/core/components/Icon'

import NextBreadcrumbs from './_breadcrumb'
import { BreadcrumbsWrapper } from './Breadcrumbs.styles'
import messages from './Breadcrumbs.messages'

export const Breadcrumbs = () => {
  const { formatMessage } = useIntl()
  const assetTableFlag = useFlag('featureDashboardChanges', false)
  const labelsMap = useMemo<{ [key: string]: string }>(() => {
    const my = assetTableFlag ? 'My Assets' : formatMessage(messages.my)

    return {
      account: formatMessage(messages.account),
      assets: formatMessage(messages.assets),
      contacts: formatMessage(messages.contacts),
      home: formatMessage(messages.home),
      share: formatMessage(messages.share),
      ptu: formatMessage(messages.ptu),
      my,
    }
  }, [])

  return (
    <BreadcrumbsWrapper>
      <HomeIcon />
      <NextBreadcrumbs
        rootLabel="Home"
        transformLabel={(slug) => labelsMap[slug] || slug}
        useDefaultStyle
      />
    </BreadcrumbsWrapper>
  )
}
