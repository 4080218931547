import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 490px;
  padding: ${({ theme: { layout } }) => `${layout.spacing(5)} ${layout.spacing(6)}`};
`

export const FormGrid = styled.div`
  display: grid;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  align-items: center;
  grid-template-columns: 200px minmax(auto, 400px) auto;
`

export const FormLegend = styled.legend`
  margin-bottom: ${({ theme: { layout } }) => layout.spacing(2)};
  padding: 0;
  line-height: ${({ theme: { layout } }) => layout.spacing(4)};
`

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: ${({ theme: { layout } }) => layout.spacing(11)};
  margin-top: ${({ theme: { layout } }) => layout.spacing(2)};
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 30px;
`

export const SelectTypeControlWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(2)};
  grid-template-columns: 200px minmax(auto, 400px) auto;
  margin-bottom: ${({ theme: { layout } }) => `${layout.spacing(2)}`};
`

export const ErrorBox = styled.div`
  position: absolute;
  top: 0;
  right: ${({ theme: { layout } }) => layout.spacing(6)};
  left: ${({ theme: { layout } }) => layout.spacing(6)};
  margin: ${({ theme: { layout } }) => `${layout.spacing(2)} 0`};
  border-radius: 10px;
  padding: ${({ theme: { layout } }) => layout.spacing(2)};
  background: ${({ theme: { colors } }) => colors.backgroundError};
  line-height: ${({ theme: { layout } }) => layout.spacing(3)};
`
