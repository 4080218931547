import { ErrorOption, FieldValues, Path, UseFormSetError } from 'react-hook-form'

export type ErrorMessages = Array<string>

export type ObjectErrors = {
  [key: string | number]:
    | ObjectErrors
    | Array<ObjectErrors>
    | ErrorMessages
    | Array<unknown>
    | unknown
}

export type FieldError = {
  field: string
  message: ErrorOption
}

export type FieldErrors = Array<FieldError>

export const convertToDotNotation = (
  object: ObjectErrors | Array<ObjectErrors> | ErrorMessages,
  prevKey?: string,
  overrides: (key: string) => string = (key) => key,
): FieldErrors =>
  Object.entries(object).reduce((acc, [_key, value]) => {
    const prev = prevKey ? `${prevKey}.` : ''
    const key = overrides(_key)

    if (key === '_error') {
      return acc
    }

    if (Array.isArray(value) && typeof value[0] === 'string') {
      return [
        ...acc,
        {
          field: `${prev}${key}`,
          message: {
            message: value[0],
            type: 'apiError',
          },
        },
      ] as FieldErrors
    }

    if (typeof value === 'object') {
      return [...acc, ...convertToDotNotation(value as ObjectErrors, `${prev}${key}`, overrides)]
    }

    return acc
  }, [] as FieldErrors)

export const setFormErrors = <TFieldValues extends FieldValues = FieldValues>(
  setError: UseFormSetError<TFieldValues>,
  errors: ObjectErrors,
  overrides?: (key: string) => string,
) => {
  convertToDotNotation(errors, '', overrides).forEach(({ field, message }) =>
    setError(field as Path<TFieldValues>, message),
  )
}
