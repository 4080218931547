import styled from 'styled-components'

export type IndicatorWrapperProps = {
  color?: string
}

export const IndicatorWrapper = styled.div`
  margin-right: ${({ theme: { layout } }) => layout.spacing(2)};
  color: ${({ theme, color }) => color || theme.colors.active};

  svg {
    display: block;
  }
`
