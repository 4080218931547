import styled from 'styled-components'

export const RadioButtonsInput = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + label {
    background: ${({ theme }) => theme.colors.focus};
  }
`
export const RadioButtonsLabel = styled.label`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  padding: ${({
    theme: {
      layout: { spacing },
    },
  }) => `${spacing(1)} ${spacing(2)}`};
  background: inherit;
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 1;
  cursor: pointer;

  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.colors.border};
  }

  :first-of-type {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  :last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
export const RadioButtonsWrapper = styled.div<{ $width: string; $disabled: boolean }>`
  display: flex;
  width: ${({ $width }) => $width};
  min-height: 56px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.disabled : theme.colors.backgroundContent};
`
