import { BaseSyntheticEvent, Fragment, useCallback } from 'react'

import {
  RadioButtonsInput,
  RadioButtonsLabel,
  RadioButtonsWrapper,
} from '@/lib/core/components/RadioButtons/RadioButtons.styles'

export type RadioButtonsItem = {
  label: string
  value: string
}
export type RadioButtonsProps = {
  name: string
  options: Array<RadioButtonsItem>
  onChange?: (value: RadioButtonsItem['value']) => void
  width?: string
  currentValue?: RadioButtonsItem['value']
  disabled?: boolean
  additionalStyles?: object
}

export const RadioButtons = ({
  name,
  options,
  onChange,
  width = 'fit-content',
  currentValue,
  disabled = false,
  additionalStyles = {},
}: RadioButtonsProps) => {
  const onLabelClick = useCallback(
    ({ target }: BaseSyntheticEvent, value: string) => {
      const input = target?.previousElementSibling

      if (input?.nodeName === 'INPUT') {
        input.checked = true
      }

      if (onChange) {
        onChange(value)
      }
    },
    [onChange],
  )

  return (
    <RadioButtonsWrapper
      style={additionalStyles ? additionalStyles : {}}
      $width={width}
      $disabled={disabled}
    >
      {options.map(({ label, value }) => {
        const key = `radiobuttons-${name}-${value}`

        return (
          <Fragment key={key}>
            <RadioButtonsInput
              id={key}
              name={name}
              value={value}
              defaultChecked={currentValue ? currentValue === value : undefined}
              disabled={disabled}
            />
            <RadioButtonsLabel htmlFor={key} onClick={(event) => onLabelClick(event, value)}>
              {label}
            </RadioButtonsLabel>
          </Fragment>
        )
      })}
    </RadioButtonsWrapper>
  )
}
