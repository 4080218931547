import { ReactNode } from 'react'
import { OptionProps } from 'react-select'

import { SelectOption } from '@/lib/core/components/Select'
import { Colors } from '@/lib/core/components/Select/Select.styles'
import {
  StyledActionOption,
  StyledOption,
} from '@/lib/core/components/Select/components/Option.styles'
import { InfoIcon, SlashIcon } from '@/lib/core/components/Icon'
import { Tooltip } from '@/lib/core/components/Tooltip'

import styles from './Option.module.css'

export type ActionOptionProps = {
  children: ReactNode
  onClick?: () => void
}

export const ActionOption = ({ children, onClick }: ActionOptionProps) => (
  <StyledActionOption onClick={onClick}>{children}</StyledActionOption>
)

export const Option = ({
  children,
  isDisabled,
  isFocused,
  isSelected,
  innerRef,
  innerProps,
  theme,
  data,
}: OptionProps<SelectOption>) => {
  const renderDisableTooltip = () => {
    let tooltipText = 'Asset currently offline'
    if (data?.data?.isOwnedFromOrganizationTo || data?.data?.optionType === 'SelectContact') {
      tooltipText = 'Cannot share back to owner'
    }
    if (isDisabled) {
      return (
        <div className={[styles.flexCenter, styles.rightIcon].join(' ')}>
          <Tooltip
            interactive
            trigger="mouseenter"
            arrowOffset={24}
            contentPadding={0}
            placement="right"
            tooltipContent={<div className={styles.tooltip}>{tooltipText}</div>}
          >
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </div>
      )
    }

    return null
  }

  return (
    <StyledOption
      aria-disabled={isDisabled}
      borderColor={(theme.colors as Colors).border}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      minHeight={`${theme.spacing.controlHeight}px`}
      // @ts-expect-error This is fine as we are using a ref
      ref={innerRef}
      {...innerProps}
    >
      {isDisabled ? (
        <div className={[styles.flexCenter, styles.leftIcon].join(' ')}>
          <SlashIcon />
        </div>
      ) : null}
      <div className={styles.optionContent}>{children}</div>
      {renderDisableTooltip()}
    </StyledOption>
  )
}
